<template>
  <div id="home">
    <span class="is-size-3">Moen Digital Service Status</span>

    <!-- <span class="is-size-4">mLINK</span>
    <button @click="refresh">Refresh</button>
    <span class="is-size-5">overall status</span>
    <div class="field is-grouped is-grouped-multiline">
      <StatusBadge name="version" status="3.2.12" color="info" />
      <StatusBadge name="dev" status="running" color="success" />
      <StatusBadge name="staging" status="running" color="success" />
      <StatusBadge name="prod" status="running" color="success" />
      <StatusBadge name="basic tests" status="failing" color="danger" />
      <StatusBadge
        name="comprehensive tests"
        status="needs rewriting"
        color="warning"
      />
      <StatusBadge name="test coverage" status="30%" color="danger" />
      <StatusBadge name="security score" status="A" color="success" />
      <StatusBadge name="sentry errors" status="45" color="danger" />
      <StatusBadge
        name="technical debt"
        status="pending vue3 upgrade"
        color="warning"
      />
      <StatusBadge name="tech stack" status="good" color="success" /> -->
    <!-- <StatusBadge name="lighthouse performance" status="83%" color="warning" />
      <StatusBadge name="lighthouse best practices" status="83%" color="warning" />
      <StatusBadge name="lighthouse mobile performance" status="61%" color="warning" /> -->
    <!-- <StatusBadge
        name="unique users last 3 months"
        status="536"
        color="link"
      />
      <StatusBadge
        name="page views last 3 months"
        status="195 390"
        color="link"
      />
    </div>
    <span class="is-size-5">modules</span>
    <div class="field is-grouped is-grouped-multiline">
      <StatusBadge name="core" status="4 bugs" color="danger" />
      <StatusBadge name="maintenance" status="19 bugs" color="danger" />
      <StatusBadge name="aware" status="in development" color="warning" />
      <StatusBadge name="admin & users" status="running" color="success" />
      <StatusBadge name="superuser" status="1 minor bug" color="warning" />
      <StatusBadge name="support" status="2 tiny bugs" color="success" />
      <StatusBadge name="remarks" status="running" color="success" />
      <StatusBadge name="discrepancies" status="4 bugs" color="warning" />
      <StatusBadge name="resting time" status="running" color="success" />
      <StatusBadge name="report module" status="running" color="success" />
      <StatusBadge name="safety module" status="1 tiny bug" color="success" />
      <StatusBadge name="scale aq portal" status="running" color="success" />
      <StatusBadge name="deck log" status="2 bugs" color="warning" />
      <StatusBadge name="consumables" status="1 tiny bug" color="success" />
      <StatusBadge name="fish farm" status="14 bugs" color="danger" />
      <StatusBadge
        name="layout overview"
        status="1 minor bug"
        color="warning"
      />
      <StatusBadge name="analytics map" status="needs rework" color="warning" />
      <StatusBadge
        name="moen ship management"
        status="running"
        color="success"
      />
      <StatusBadge name="service module" status="running" color="success" />
      <StatusBadge
        name="equipment library"
        status="not ready"
        color="warning"
      />
      <StatusBadge name="webapp stats" status="running" color="success" />
      <StatusBadge name="order module" status="running" color="success" />
    </div>
    <hr />

    <span class="is-size-4">CONNECT</span>
    <div class="field is-grouped is-grouped-multiline">
      <StatusBadge name="version" status="unknown" color="grey" />
      <StatusBadge name="dev" status="running" color="success" />
      <StatusBadge name="staging" status="NA" color="" />
      <StatusBadge name="prod" status="not working" color="danger" />
      <StatusBadge name="monitor 4.0" status="10 boats" color="info" />
      <StatusBadge name="monitor 5.0" status="7 boats" color="info" />
    </div>
    <hr />

    <span class="is-size-4">VPA</span>
    <div class="field is-grouped is-grouped-multiline">
      <StatusBadge name="version" status="unknown" color="" />
      <StatusBadge name="status" status="unknown" color="" />
    </div>
    <hr />

    <span class="is-size-4">mSPEK</span>
    <div class="field is-grouped is-grouped-multiline">
      <StatusBadge name="version" status="unknown" color="grey" />
      <StatusBadge name="prod" status="running" color="success" />
      <StatusBadge name="automatic tests" status="none" color="warning" />
      <StatusBadge name="security score" status="C" color="warning" />
      <StatusBadge
        name="technical debt"
        status="django templates frontend"
        color="warning"
      />
      <StatusBadge name="tech stack" status="good" color="success" />
    </div>
    <hr />

    <span class="is-size-4">3D SPHERE</span>
    <div class="field is-grouped is-grouped-multiline">
      <StatusBadge name="version" status="unknown" color="grey" />
      <StatusBadge name="build" status="passing" color="success" />
      <StatusBadge name="number of boats" status="7" color="info" />
      <StatusBadge name="VR" status="prototype only" color="warning" />
      <StatusBadge name="AQS 2612" status="90%" color="primary" />
      <StatusBadge name="AQS 2112" status="45%" color="warning" />
    </div> -->

    <br />
    <br />
  </div>
</template>

<script>
import StatusBadge from "@/components/StatusBadge.vue";
import axios from "axios";
import qs from "qs";

export default {
  name: "HomeView",
  components: {
    StatusBadge,
  },
  // methods: {
  //   async refresh() {
  //     const baseUrl = "http://localhost:1337";

  //     const response = await axios.get(`${baseUrl}/jira/count-core-issues`);
  //     if (response?.ok) {
  //       const data = await response.data;
  //       console.log("data :>> ", data);
  //     } else {
  //       console.warn("response not ok");
  //     }
  //   },
  // },
};
</script>

<style>
#home {
  padding: 1rem;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
