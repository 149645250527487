<template>
  <div class="control">
    <div class="tags has-addons">
      <span class="tag is-dark">{{ name }}</span>
      <span class="tag" :class="`is-${color}`">{{ status }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusBadge",
  props: ["name", "status", "color"],
};
</script>
